import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RememberMeGuard } from './core/guards/remember-me.gurard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./entry/entry.module').then((m) => m.EntryModule), canActivate: [RememberMeGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
