import { Injectable, TemplateRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { PrintableClaim } from '../models/printable-claim';
@Injectable({
  providedIn: 'root',
})
export class ClaimService {

  printIt$ = new Subject<PrintableClaim>();
  constructor(private http: HttpClient, private router: Router) {

  }

  fileUpload(file) {

    return this.http.post(environment.userApi + '/api/user/file/upload',file);
  }

  addClaim(claim){
    return this.http.post(environment.claimsApi + '/api/cpm/claims/addClaim', claim);
  }

  saveClaim(claimId, step, claimDetails) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/fdr/stage${step}`, claimDetails);
  }

  saveHBClaim(claimId, step, claimDetails) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/hbStage${step}`, claimDetails);
  }

  saveExEmpDetails(claimId, claimDetails) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/exEmployee`, claimDetails);
  }

  saveGSADetails(claimId, claimDetails) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/gsAuthorityClaim`, claimDetails);
  }

  saveLandOwnerDetails(claimId, claimDetails) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/landOwner`, claimDetails);
  }

  getAllClaims(){
    return this.http.get(environment.claimsApi + `/api/cpm/claims/user/claims`);
  }

  getClaim(claimId){
    return this.http.get(environment.claimsApi + `/api/cpm/claims/${claimId}`);
  }

  getClaimRevision(rvsnId){
    return this.http.get(environment.claimsApi + `/api/cpm/claims/${rvsnId}/details`);
  }

  saveHomeBuyerClaim(claimId, claimDetails, step) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/${step}`, claimDetails);
  }
  saveRWAClaim(claimId, claimDetails) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/rwaClaim`, claimDetails);
  }

  saveBFNAClaim(claimId, claimDetails) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/bfnaClaim`, claimDetails);
  }

  saveOCVClaim(claimId, claimDetails) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/ocClaim`, claimDetails);
  }

  saveJVPClaim(claimId, claimDetails) {
    return this.http.post(environment.claimsApi + `/api/cpm/claims/${claimId}/jvpClaim`, claimDetails);
    }
    
  raiseConcern(concern: object) {
    return this.http.post(environment.userApi + `/api/user/user/raise/claim/concern`, concern);
  }

  getRaiseConcern(pageSize = 10, pageNo=0, startDate="", endDate="") {
    return this.http.post(environment.userApi + `/api/user/user/reports/claim/concerns?pageNo=${pageNo}&pageSize=${pageSize}&fromDate=${startDate}&toDate=${endDate}`, {});
  }

  historyOfClaims() { 
    return this.http.get(environment.claimsApi + `/api/cpm/claims/all`);
  }
  
  updateClaimStatus(claimId){
    return this.http.put(environment.claimsApi + `/api/cpm/claims/status/update/${claimId}`,null); 
  }

  
  downloadClaim(claimId):Observable<any>{
    return this.http.post(environment.claimsApi + `/api/cpm/claims/pdf/${claimId}`, { responseType: 'blob' });
  }
  
}