import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ClaimService } from '../services/claim.service';

@Component({
  selector: 'app-claim-printer',
  templateUrl: './claim-printer.component.html',
  styleUrls: ['./claim-printer.component.scss'],
})
export class ClaimPrinterComponent implements OnInit {
  @ViewChild('printableDiv') printableDiv;
  constructor(private claimService: ClaimService, public viewContainerRef: ViewContainerRef) {}
  currentTemplate: TemplateRef<any>;
  heading:string;
  today = new Date();

  ngOnInit(): void {
    this.claimService.printIt$.subscribe((printableClaim) => {
      this.currentTemplate =  printableClaim.content;
      this.heading = printableClaim.heading;
      setTimeout( () => window.print(),10);
    });
  }
}
