import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private router: Router) {
    const cachedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    if (cachedUser) {
      const user = JSON.parse(cachedUser);
      this.accessToken = JSON.parse(localStorage.getItem('token'));
      this.refreshToken = JSON.parse(localStorage.getItem('token'));
      this.userB.next(user);
    }
  }

  private userB = new BehaviorSubject<User>(null);
  public user$ = this.userB.asObservable();

  accessToken: string;
  refreshToken: string;

  generateOtp(mobileNumber: object) {
    return this.http.post(environment.userApi + `/api/user/public/otp/generate`, mobileNumber);
  }

  verifyOtp(otp: object) {
    return this.http.post(environment.userApi + `/api/user/public/otp/verify`, otp);
  }

  register(userDetails, capcha) {
    return this.http.post(environment.userApi + `/api/user/public/signup?g-recaptcha-response=${capcha}`, userDetails);
  }
  myProfile() {
    return this.http.get(environment.userApi + `/api/user/user`);
  }
  getS3Images(uniqueIdentifier) {
    return this.http.get(environment.userApi + `/api/user/file/${uniqueIdentifier}`, {
      responseType: 'blob',
    });
  }

  public async downloadResource(id: string): Promise<Blob> {
    const file = await this.http
      .get<Blob>(environment.userApi + '/api/user/file/' + id, { responseType: 'blob' as 'json' })
      .toPromise();
    return file;
  }


public async downloadFile(id): Promise<string> {
  const blob = await this.downloadResource(id);
  const url = window.URL.createObjectURL(blob);
  return url;
}

  passwordRestMail(email) {
    return this.http.post(environment.userApi + `/api/user/public/password/link`, email);
  }

  resetPassword(newPassword, resetToken) {
    return this.http.post(environment.userApi + `/api/user/public/password/reset`, {
      password: newPassword,
      token: resetToken,
    });
  }
  changePassword(password, oldPassword) {
    return this.http.post(environment.userApi + `/api/user/password/changePassword`, {
      password: password,
      oldPassword: oldPassword,
    });
  }
  login(loginDetails) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };
    return this.http.post<any>(environment.userApi + `/api/user/public/user/login`, loginDetails.toString(), options);
  }

  refreshSession() {
    return new Promise<boolean>((resolve, reject) => {
      const options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      };
      const body = new URLSearchParams();
      if (!this.getRefreshToken()) {
        return resolve(true);
      }
      body.set('refresh_token', this.getRefreshToken());
      body.set('grant_type', 'refresh_token');
      this.http.post<any>(environment.userApi + `/api/user/public/user/login`, body.toString(), options).subscribe(
        (newTokens) => {
          const user = { ...this.getUser(), access_token: newTokens.access_token, refresh_token: newTokens.refresh_token };
          this.storeUserInfo(user);
          return resolve(true);
        },
        (err) => {
          this.logout();
          return resolve(true);
        },
      );
    });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.userB.next(null);
    this.accessToken = null;
    this.refreshToken = null;
  }

  getAccessToken() {
    return localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token') || sessionStorage.getItem('refresh_token');
  }

  getUser() {
    const cachedUser = localStorage.getItem('user');
    return JSON.parse(cachedUser);
  }

  storeUserInfo(userData, rememberMeChecked?) {
    this.userB.next(userData);
    rememberMeChecked = localStorage.getItem('user') || rememberMeChecked;
    if (userData) {
      if (rememberMeChecked) {
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('access_token', userData.access_token);
        localStorage.setItem('refresh_token', userData.refresh_token);
      } else {
        sessionStorage.setItem('user', JSON.stringify(userData));
        sessionStorage.setItem('access_token', userData.access_token);
        sessionStorage.setItem('refresh_token', userData.refresh_token);
      }
    }
  }

  // panVerify(panNumber) {
  //   return this.http.get(environment.userApi + `/api/user/public/pan/verify?panNum=${panNumber}`);
  // }

  panVerify(panNumber,name) {
    return this.http.post(environment.userApi + `/api/user/public/pan/verify`, {
      panNum:panNumber,
      name:name
    });
  }

  fetchPan(panNumber) {
    return this.http.get(environment.userApi + `/api/user/public/pan/existing?panNum=${panNumber}`);
  }
}
